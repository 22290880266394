import React from 'react'

const DeployConfiguration = () => {
    return (
        <form className="form-container">
            <button className="input-submit">Deploy Configuration</button>
        </form>
    )
}

export default DeployConfiguration
