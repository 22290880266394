import React from 'react'

function Header() {
    const headerStyle = {
        padding: '20px 0',
        lineHeight: '1.5em',
    }

    return (
        <header style={headerStyle}>
            <h1
                style={{
                    fontSize: '2rem',
                    fontWeight: '1200',
                    marginBottom: '2rem',
                    lineHeight: '1em',
                    color: '#ececec',
                    textAlign: 'center',
                }}
            >
                Welcome to the Node Client Configuration Management Application
            </h1>
        </header>
    )
}

export default Header
