import React from 'react'

import TasksList from './TasksList'
import Header from './Header'
import ChocolateyApplicationsInput from './ChocolateyApplicationsInput'
import DeployConfiguration from './DeployConfiguration'
import ClientSelectionDropdown from './ClientSelectionDropdown'
import { clientNames } from './ClientNames'

class Home extends React.Component {
    state = {
        tasks: [
            {
                id: 1,
                title: 'Company Branding Configuration',
                apitaskname: 'CompanyBrandingConfiguration',
                selected: false,
            },
            {
                id: 2,
                title: 'AutoPilot Policy Creation',
                apitaskname: 'AutoPilotPolicyCreation',
                selected: false,
            },
            {
                id: 3,
                title: 'Enrollment Status Page Policy Update',
                apitaskname: 'EnrollmentStatusPagePolicyUpdate',
                selected: false,
            },
            {
                id: 4,
                title: 'Windows Hello For Business Policy',
                apitaskname: 'WindowsHelloForBusinessPolicy',
                selected: false,
            },
            {
                id: 5,
                title: 'Personal Device Enrollment Restriction Policy',
                apitaskname: 'PersonalDeviceEnrollmentRestrictionPolicy',
                selected: false,
            },
            {
                id: 6,
                title: 'Windows 10 Compliance Policy',
                apitaskname: 'Windows10CompliancePolicy',
                selected: false,
            },
            {
                id: 7,
                title: 'OneDrive Administrative Template Policy',
                apitaskname: 'OneDriveAdministrativeTemplatePolicy',
                selected: false,
            },
            {
                id: 8,
                title: 'BitLocker Policy',
                apitaskname: 'BitLockerPolicy',
                selected: false,
            },
            {
                id: 9,
                title: 'Security Baseline Policy',
                apitaskname: 'SecurityBaselinePolicy',
                selected: false,
            },
            {
                id: 10,
                title: 'Lock Screen Password Reset Policy',
                apitaskname: 'LockScreenPasswordResetPolicy',
                selected: false,
            },
            {
                id: 11,
                title: 'Microsoft 365 Apps Policy',
                apitaskname: 'Microsoft365AppsPolicy',
                selected: false,
            },
            {
                id: 12,
                title: 'Windows Storage Sense Policy',
                apitaskname: 'WindowsStorageSensePolicy',
                selected: false,
            },
            {
                id: 13,
                title: 'Windows Default App Associations Policy',
                apitaskname: 'WindowsDefaultAppAssociationsPolicy',
                selected: false,
            },
            {
                id: 14,
                title: 'Windows TimeZone Configuration Policy',
                apitaskname: 'WindowsTimeZoneConfigurationPolicy',
                selected: false,
            },
            {
                id: 15,
                title: 'Windows Authentication Configuration Policy',
                apitaskname: 'WindowsAuthenticationConfigurationPolicy',
                selected: false,
            },
            {
                id: 16,
                title: 'Enable Endpoint Analytics',
                apitaskname: 'EnableEndpointAnalytics',
                selected: false,
            },
            {
                id: 17,
                title: 'Mozilla Firefox SSO Proactive Remediation Policy',
                apitaskname: 'MozillaFirefoxSsoProactiveRemediationPolicy',
                selected: false,
            },
            {
                id: 18,
                title: 'Show Windows File Extensions Proactive Remediation Policy',
                apitaskname: 'ShowWindowsFileExtensionsProactiveRemediationPolicy',
                selected: false,
            },
            {
                id: 19,
                title: 'Disable Internet Explorer Proactive Remediation Policy',
                apitaskname: 'DisableInternetExplorerProactiveRemediationPolicy',
                selected: false,
            },
            {
                id: 20,
                title: 'Windows Update Ring Policy',
                apitaskname: 'WindowsUpdateRingPolicy',
                selected: false,
            },
            {
                id: 21,
                title: 'Google Chrome Extension Installation Script',
                apitaskname: 'GoogleChromeExtensionInstallationScript',
                selected: false,
            },
            {
                id: 22,
                title: 'Webroot Chrome Extension Installation Script',
                apitaskname: 'WebrootChromeExtensionInstallationScript',
                selected: false,
            },
            {
                id: 23,
                title: 'Compliant Device Or Mfa Policy',
                apitaskname: 'CompliantDeviceOrMfaPolicy',
                selected: false,
            },
            {
                id: 24,
                title: 'Device Cleanup Rule Policy',
                apitaskname: 'DeviceCleanupRulePolicy',
                selected: false,
            },
            {
                id: 25,
                title: 'Mobile Application Management Policies',
                apitaskname: 'MobileApplicationManagementPolicies',
                selected: false,
            },
            {
                id: 26,
                title: 'Microsoft 365 Defender Policies',
                apitaskname: 'Microsoft365DefenderPolicies',
                selected: false,
            },
            {
                id: 27,
                title: 'Microsoft Defender Endpoint Security Recommendations',
                apitaskname: 'MicrosoftDefenderEndpointSecurityRecommendations',
                selected: false,
            },
            {
                id: 28,
                title: 'Microsoft Secure Score Recommendations',
                apitaskname: 'MicrosoftSecureScoreRecommendations',
                selected: false,
            },
        ],
    }

    handleChange = (id) => {
        this.setState((prevState) => ({
            tasks: prevState.tasks.map((task) => {
                if (task.id === id) {
                    return {
                        ...task,
                        selected: !task.selected,
                    }
                }
                return task
            }),
        }))
    }

    addChocolateyApplication = (title) => {
        const newChocolateyApplication = {
            id: 29,
            title: title,
            apitaskname: 'ChocolateyApplicationsDeploymentScript',
            selected: false,
        }
        this.setState({
            tasks: [...this.state.tasks, newChocolateyApplication],
        })
    }

    render() {
        return (
            <div className="container">
                <div className="inner">
                    <Header />
                    <ClientSelectionDropdown
                        isSearchable
                        isMulti
                        placeHolder="Select clients to configure..."
                        options={clientNames}
                        onChange={(value) => console.log(value)}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <TasksList tasks={this.state.tasks} handleChangeProps={this.handleChange} />
                    <ChocolateyApplicationsInput
                        addChocolateyApplicationProps={this.addChocolateyApplication}
                    />
                    <DeployConfiguration />
                </div>
            </div>
        )
    }
}

export default Home
