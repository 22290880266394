import React, { useState } from 'react'

const ChocolateyApplicationsInput = (props) => {
    const [title, setTitle] = useState('')

    const onChange = (e) => {
        setTitle(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (title.trim()) {
            props.addChocolateyApplicationProps(title)
            setTitle('')
        } else {
            alert('Please write item')
        }
    }

    return (
        <form onSubmit={handleSubmit} className="form-container">
            <input
                type="text"
                className="input-text"
                placeholder="Enter chocolatey applications here"
                value={title}
                name="title"
                onChange={onChange}
            />
            <button className="input-submit">Add</button>
        </form>
    )
}

export default ChocolateyApplicationsInput
