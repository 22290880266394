import React from 'react'

import styles from './TaskItem.module.css'

class TaskItem extends React.Component {
    render() {
        const selectedStyle = {
            fontStyle: 'italic',
            color: '#595959',
            opacity: 0.4,
            textDecoration: 'line-through',
        }

        const { selected, id, title } = this.props.task

        return (
            <li className={styles.item}>
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    checked={selected}
                    onChange={() => this.props.handleChangeProps(id)}
                />
                <span style={selected ? selectedStyle : null}></span>
                {title}
            </li>
        )
    }
}

export default TaskItem
