import React from 'react'
import TaskItem from './TaskItem'

class TasksList extends React.Component {
    render() {
        return (
            <ul>
                {this.props.tasks.map((task) => (
                    <TaskItem
                        key={task.id}
                        task={task}
                        handleChangeProps={this.props.handleChangeProps}
                    />
                ))}
            </ul>
        )
    }
}

export default TasksList
