import React from 'react'
import ReactDOM from 'react-dom'

//component file
import Home from './pages/components/Home'

//Stylesheet
import './App.css'

ReactDOM.render(
    <React.StrictMode>
        <Home />
    </React.StrictMode>,
    document.getElementById('root'),
)
